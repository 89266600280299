const sendFile = function sendFile(
  file: File,
  path: string,
  authToken: string,
  // mappingData: any,
  otherFormData: any[],
  resolve: (x: any) => void,
  reject: (x: any) => void
) {
  var data = new FormData();
  var request = new XMLHttpRequest();

  // File selected by the user
  // In case of multiple files append each of them
  data.append("files", file);

  otherFormData.forEach(({ field, fieldData }) => {
    data.append(field, JSON.stringify(fieldData));
  });

  // data.append("mapping", JSON.stringify(mappingData));

  // AJAX request finished
  request.addEventListener("load", function(e) {
    // request.response will hold the response from the server
    if ([200, 206].includes(request.response.status)) {
      resolve({ response: request.response, path: path });
    } else {
      reject({ response: request.response, path: path });
    }
  });

  // Upload progress on request.upload
  request.upload.addEventListener("progress", function(e) {
    var percent_complete = (e.loaded / e.total) * 100;

    // Percentage of upload completed
    console.log("percent_complete: ", percent_complete + "%");
  });

  // If server is sending a JSON response then set JSON response type
  request.responseType = "json";

  // Send POST request to the server side script
  // request.open('post', window.twc.config.host + path);
  request.open("post", path);
  request.setRequestHeader("auth_token", authToken);

  // https://db4khk3l60.execute-api.ap-southeast-2.amazonaws.com/testing
  request.send(data);
};

export default sendFile;
