import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
  Dispatch
} from "react";

const FileContext = createContext<
  [File | null, Dispatch<SetStateAction<File | null>>]
>([null, file => file]);

const useFileContext = () => {
  return useContext(FileContext);
};

const ProvideFile = ({ children }) => {
  const fileState = useState(null);
  return (
    <FileContext.Provider value={fileState}>{children}</FileContext.Provider>
  );
};

export { FileContext, useFileContext, ProvideFile };
