import React, { FunctionComponent } from "react";

const Loading: FunctionComponent<{ from?: string; content?: string }> = ({
  from,
  content = "Loading"
}) => {
  // console.log("loading from: ", from);
  return (
    <div
      className="flex flex-col items-center justify-center text-gray-700"
      style={{ minHeight: "30vh" }}
    >
      {content}...
      <svg width="44" height="44" className="svg-loading">
        <use xlinkHref="#svg-loading"></use>
      </svg>
    </div>
  );
};

export default Loading;
