import React, {
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useState
} from "react";

const HeadersTypeContext = createContext<
  [string, Dispatch<SetStateAction<"" | "col_name" | "col_number">>]
>(["", type => type]);

const useHeadersTypeContext = () => {
  return useContext(HeadersTypeContext);
};

const ProvideHeadersType = ({ children }) => {
  const headersTypeState = useState("");
  return (
    <HeadersTypeContext.Provider value={headersTypeState}>
      {children}
    </HeadersTypeContext.Provider>
  );
};

export { HeadersTypeContext, ProvideHeadersType, useHeadersTypeContext };
