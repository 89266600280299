import React, { useState, FunctionComponent, useEffect, Dispatch } from "react";

let radioLabelElems;
interface IRadio {
  htmlid: string;
  value: string;
  title: string;
  desc: string;
  onFileTypeRadioChange: (evt: React.FormEvent<HTMLInputElement>) => void;
}
const RadioOption: FunctionComponent<IRadio> = ({
  htmlid,
  value,
  title,
  desc,
  onFileTypeRadioChange
}) => {
  return (
    <div className="flex items-center mt-2">
      <input
        id={htmlid}
        value={value}
        type="radio"
        name="api-type-radio"
        className="hidden"
        onChange={onFileTypeRadioChange}
      />
      <label
        htmlFor={htmlid}
        className="flex cursor-pointer text-lg flex-col w-full px-4 py-2 hover:border-twc-logo hover:border rounded-sm"
        // data-hover-on-id="inventory-stock-explained"
      >
        {/* <span className="w-6 h-6 inline-block mr-2 rounded-full border border-grey flex-no-shrink" style={{ minWidth: "1.5rem" }}></span> */}
        <div className="font-semibold">{title}</div>
        <div className="text-sm">{desc}</div>
      </label>
    </div>
  );
};

const UploadTypeRadio: FunctionComponent<{
  onFileTypeRadioChange: (evt: React.FormEvent<HTMLInputElement>) => void;
}> = ({ onFileTypeRadioChange }) => {
  // const [token, setToken] = useState("");

  // useEffect(() => {
  //   radioLabelElems = document.querySelectorAll(".form-radio-btns label");
  //   radioLabelElems.forEach(elem => {
  //     elem.addEventListener("mouseenter", evt => {
  //       const item = evt.target.dataset["hoverOnId"];
  //       window[item] && window[item].classList.remove("hidden");
  //     });
  //     elem.addEventListener("mouseleave", evt => {
  //       const item = evt.target.dataset["hoverOnId"];
  //       window[item] && window[item].classList.add("hidden");
  //     });
  //   });
  // }, []);
  return (
    <div className="mt-6 flex-col">
      <div className="form-radio-btns">
        <RadioOption
          htmlid="upload-inventory"
          value="csv_mapping_inventory"
          title="Product Create/Update"
          desc="If you want to update existing items or create brand new items select this option"
          onFileTypeRadioChange={onFileTypeRadioChange}
        />

        <RadioOption
          htmlid="upload-combined"
          value="csv_mapping_combined"
          title="Product Create/Update with Stock on Hand"
          desc="If you want update/create simultaneously both new products and their stock on hand"
          onFileTypeRadioChange={onFileTypeRadioChange}
        />

        <RadioOption
          htmlid="upload-stock"
          value="csv_mapping_stock"
          title="Stock on Hand Update"
          desc="To update existing items with their new stock on hand level"
          onFileTypeRadioChange={onFileTypeRadioChange}
        />
      </div>
    </div>
  );
};

export default UploadTypeRadio;
