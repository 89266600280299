import "@babel/polyfill";
import React, { useState, useEffect, useContext, createContext } from "react";
import {
  createCookie,
  readCookie,
  eraseCookie,
  axiosUserAuth
} from "~utils/utils";
import useDataApi from "./useDataApi";

// import axios from "axios";
// from : https://usehooks.com/useAuth/
// import * as firebase from "firebase/app";
// import "firebase/auth";

// Add your Firebase credentials
// firebase.initializeApp({
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   appID: ""
// });

const AuthContext = createContext();
const authCookieName = "authToken";

interface IAuth {
  userAxiosState: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  };
  user: {
    [x: string]: any;
    server_time: string;
    display_name: string;
    csv_mapping_inventory: {} | null;
    csv_mapping_stock: {} | null;
    csv_mapping_combined: {} | null;
    roles: {
      role_vendor: boolean;
      role_content_mgmt: boolean;
      role_admin: boolean;
    };
  };
  token: string;
  signin: (userToken: string) => void;
  fns: {
    updateUser: (type: string, newUserProps: any) => void;
    userHasAnyMapping: () => boolean;
  };
  signout: () => void;
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): IAuth => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [token, setToken] = useState(() => {
    const valToken = readCookie(authCookieName);
    return valToken ? valToken : "";
  });
  const [user, setUser] = useState<
    | undefined
    | {
        display_name: string;
        csv_mapping_inventory: {};
        csv_mapping_stock: {};
        csv_mapping_combined: {};
        roles: {
          role_vendor: boolean;
          role_content_mgmt: boolean;
          role_admin: boolean;
        };
      }
  >();
  const [userAxiosState, setUserAxiosState] = useDataApi(null, null);
  // const useDataApi()

  const fns = {
    updateUser: (type: string, newUserProps: any) => {
      setUser({ ...user, ...{ [type]: newUserProps } });
    },
    userHasAnyMapping: () =>
      [
        user.csv_mapping_inventory,
        user.csv_mapping_stock,
        user.csv_mapping_combined
      ].some(x => x)
  };

  const signin = (userToken: string) => {
    setToken(userToken);
    setUserAxiosState(axiosUserAuth(userToken));
  };

  const signout = () => {
    setUser(undefined);
    setToken("");
    setUserAxiosState("RESET");
    eraseCookie(authCookieName);
  };

  /*
  Subscribe to user on mount
  Because this sets state in the callback it will cause any ...
  ... component that utilizes this hook to re-render with the ...
  ... latest auth object.
  */
  useEffect(() => {
    if (token) {
      setUserAxiosState(axiosUserAuth(token));
    }
  }, []);

  useEffect(() => {
    if (userAxiosState.isError) {
      setUser(undefined);
      setUserAxiosState("RESET");
      eraseCookie(authCookieName);
    } else {
      if (token && !userAxiosState.isLoading) {
        createCookie(authCookieName, token);
        setUser(userAxiosState.data);
      }
    }
  }, [userAxiosState]);

  // Return the user object and auth methods
  return {
    userAxiosState,
    user,
    token,
    signin,
    fns,
    signout
  };
}
