interface IOptionsMainType {
  [key: string]: IOptionsType;
}
interface IOptionsType {
  datatype: string;
  required: boolean;
  description: string;
}
interface ISelectOptions {
  label: string;
  value: string;
}
interface ConfigType {
  [x: string]: string | {};
  urlData: { [x: string]: string[] };
  optionTypes: { [x: string]: string[] };
  userMapping: { [x: string]: string };
}
const COL_NAME = "col_name";
const COL_NUMBER = "col_number";
const config: ConfigType = {
  DASHBOARD_URL: "/dashboard",
  MAPPING_URL: "/mapping",
  LOGIN_URL: "/login",
  RESULTS_URL: "/results",
  REVIEWS_SEARCH_URL: "/reviews",
  REVIEWS_LATEST_URL: "/reviews/latest",
  IMG_UPLOAD_URL: "/images",
  IMG_REVIEW_URL: "/image-approval",
  ERRORS_REVIEW_URL: "/errors-review",
  UPLOAD_URL: "/",
  // host: "http://localhost:4100",
  // host: "https://db4khk3l60.execute-api.ap-southeast-2.amazonaws.com/testing",
  host: "https://db4khk3l60.execute-api.ap-southeast-2.amazonaws.com/v1",
  urlData: {
    inventoryOnly: ["/inventory/update/csv"],
    stockOnly: ["/stock/update/csv"],
    inventoryAndStock: ["/inventory/update/csv", "/stock/update/csv"],

    csv_mapping_inventory: ["/inventory/update/csv"],
    csv_mapping_stock: ["/stock/update/csv"],
    csv_mapping_combined: ["/inventory/update/csv", "/stock/update/csv"]
  },
  optionTypes: {
    inventoryOnly: ["fields_inventory"],
    stockOnly: ["fields_stock"],
    inventoryAndStock: ["fields_inventory", "fields_stock"],

    csv_mapping_inventory: ["fields_inventory"],
    csv_mapping_stock: ["fields_stock"],
    csv_mapping_combined: ["fields_inventory", "fields_stock"]
  },
  userMapping: {
    inventoryOnly: "csv_mapping_inventory",
    stockOnly: "csv_mapping_stock",
    inventoryAndStock: "csv_mapping_combined"
  }
  // reverseUserMapping: {
  //   csv_mapping_inventory: "inventoryOnly",
  //   csv_mapping_stock: "stockOnly",
  //   csv_mapping_combined: "inventoryAndStock"
  // }
};

const globalHeaders = (user: any) => {
  if (!user) {
    return {};
  }
  const globalHeader: { [key: string]: string } = {};
  [
    "csv_mapping_inventory",
    "csv_mapping_stock",
    "csv_mapping_combined"
  ].forEach(type => {
    if (user[type] && user[type].csv_mapping_type === COL_NAME) {
      Object.entries(user[type].csv_mapping_columns).forEach(
        ([theirField, ourField]) => {
          globalHeader[theirField] = ourField;
        }
      );
    }
  });
  return globalHeader;
};

const buildAxios = (
  token: string,
  method: string,
  path: string,
  data: any = ""
) => {
  const base = {
    method: method,
    url: `${config.host}${path}`,
    headers: {
      auth_token: token
    },
    json: true
  };
  return data ? { ...base, ...{ data } } : base;
};

const axiosUserAuth = (token: string) => buildAxios(token, "GET", "/user");
const axiosSearchProviders = (token: string) =>
  buildAxios(token, "GET", "/reviews");
const axiosGetImagesForApproval = (token: string) =>
  buildAxios(token, "GET", "/administration/images/approval");

const axiosReviewErrors = (token: string) =>
  buildAxios(token, "GET", "/administration/skucreate/errors");
const axiosSkuCreateReviewErrors = (token: string) =>
  buildAxios(token, "GET", "/administration/erp/skucreate");
export const fetchErrorsMappingUrl = `${config.host}/administration/validationmapping`;
export const postMappingCreateUrl = `${fetchErrorsMappingUrl}/create`;
export const postErrsUpdateMappingUrl = `${config.host}/administration/erp/skucreate`;

const axiosPostMappings = ({ token, data }: { token: string; data: any }) =>
  buildAxios(token, "POST", "/user/csv_field_mapping", data);
const axiosSearchReviews = ({ token, data }: { token: string; data: any }) =>
  buildAxios(token, "POST", "/reviews/search", data);
const axiosImageApproval = ({ token, data }: { token: string; data: any }) =>
  buildAxios(token, "POST", "/administration/images/approval", data);
const axiosImageReprocess = ({ token, data }: { token: string; data: any }) =>
  buildAxios(token, "POST", "/administration/images/reprocess", data);

const createCookie = function createCookie(
  name: string,
  value: string,
  days: number = 0
) {
  const sameSite = "SameSite=Strict";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else {
    var expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/" + "; " + sameSite;
};

const readCookie = function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

const eraseCookie = function eraseCookie(name) {
  createCookie(name, "", -1);
};

const twcFieldsOptions = data =>
  data
    ? [
        { label: "Map Field / Leave Unmapped", value: "" },
        { label: "-- Required --", value: "", disabled: true },
        ...data.required,
        data.requiredOneOf.length
          ? {
              label: "-- Required One from below --",
              value: "",
              disabled: true
            }
          : null,
        ...data.requiredOneOf,
        data.optional.length
          ? { label: "-- Optional --", value: "", disabled: true }
          : null,
        ...data.optional
      ].filter(x => x)
    : [{}];

const reducer = (predicate: (val: boolean) => boolean) => (
  acc: [],
  curr: any
) => {
  const [key, data] = curr;
  predicate(data.required) && acc.push({ label: data.description, value: key });
  return acc;
};

const processOptionsByType = (
  data: IOptionsMainType,
  fieldType: string,
  predicate: (val: boolean) => boolean
) =>
  data &&
  data[fieldType] &&
  Object.entries(data[fieldType]).reduce(reducer(predicate), []);
// Object.entries(data[fieldType]).filter(predicate).map(data => { label: data.description, value: data.value })

const processOptions = (apiTypes, data) =>
  apiTypes.reduce(
    (acc, type) => {
      processOptionsByType(data, type, val => val /* isRequired */).forEach(
        elem => {
          acc.required.filter(e => e.value === elem.value).length ||
            acc.required.push(elem);
        }
      );
      processOptionsByType(data, type, val => !val /* isOptional */).forEach(
        elem => {
          if (/^stock_/.test(elem.value)) {
            acc.requiredOneOf.filter(e => e.value === elem.value).length ||
              acc.requiredOneOf.push(elem);
          } else {
            acc.optional.filter(e => e.value === elem.value).length ||
              acc.optional.push(elem);
          }
        }
      );
      return acc;
    },
    { required: [], requiredOneOf: [], optional: [] }
  );

export {
  COL_NAME,
  COL_NUMBER,
  axiosGetImagesForApproval,
  axiosImageApproval,
  axiosImageReprocess,
  axiosPostMappings,
  axiosSearchProviders,
  axiosSearchReviews,
  axiosReviewErrors,
  axiosSkuCreateReviewErrors,
  axiosUserAuth,
  buildAxios,
  config,
  createCookie,
  eraseCookie,
  globalHeaders,
  processOptions,
  readCookie,
  twcFieldsOptions
};

// So for the mapping, can you POST to

// https://db4khk3l60.execute-api.ap-southeast-2.amazonaws.com/testing/user/csv_field_mapping
// {
//     "csv_mapping_type": "col_name"
//     "csv_mapping_columns": {
//         "their_field_2": "our_field_2",
//         "their_field_3": "our_field_3",
//         "their_field_4": "our_field_4"
//     }
// }

// {
//     "csv_mapping_type": "col_number"
//     "csv_mapping_columns": [
//         "our_field_1",
//         null,
//         "our_field_5",
//         "out_field_3",
//         null,
//         null
//     ]
// }

// I'll provide the same JSON in GET

// https://db4khk3l60.execute-api.ap-southeast-2.amazonaws.com/testing/user
