import React from "react";

import { navigate, Link, Match } from "@reach/router";
import { config } from "~utils/utils";
import { useAuth } from "~hooks/useAuth";

const NavLink = props => {
  const { stillActive, ...newProps } = props;
  return (
    <Link
      {...newProps}
      getProps={({ isCurrent }) => {
        return isCurrent && !stillActive
          ? { className: props.className + " border-white pointer-events-none" }
          : { className: props.className };
      }}
    />
  );
};

const Header = () => {
  const auth = useAuth();

  const vendorSignOut = () => {
    auth.signout();
    navigate(config.LOGIN_URL as string, { replace: true });
  };

  const linkCls =
    "cursor-pointer text-white border-b-4 hover:border-twc-btn-login-100 border-transparent mr-8 pb-1";

  return (
    <header className="flex items-center justify-between flex-wrap bg-twc-logo h-48">
      <div className="container m-auto">
        <div className="flex items-center justify-center text-white">
          <div className="w-1/3 md:w-1/3 lg:w-1/4 pb-2">
            <img src={require(`./assets/img/twc-logo.svg`)} />
          </div>
        </div>
        {auth && auth.user && (
          <div className="flex flex-col md:flex-row items-center justify-between pt-10">
            <div className="flex text-base justify-between uppercase">
              {auth.user.roles.role_vendor && (
                <>
                  <NavLink className={linkCls} to={config.UPLOAD_URL}>
                    Upload Product
                  </NavLink>
                  <NavLink className={linkCls} to={config.RESULTS_URL}>
                    Your Listings
                  </NavLink>
                </>
              )}
              {auth.user.roles.role_content_mgmt && (
                <>
                  <NavLink
                    className={linkCls}
                    to={config.REVIEWS_SEARCH_URL}
                    stillActive
                  >
                    Reviews
                  </NavLink>
                  <NavLink className={linkCls} to={config.IMG_REVIEW_URL}>
                    Image Approval
                  </NavLink>
                  {/* TODO: visible only to admin user */}
                  <NavLink className={linkCls} to={config.ERRORS_REVIEW_URL}>
                    Errors Review
                  </NavLink>
                </>
              )}
              {/* <NavLink className={linkCls} to={config.IMG_UPLOAD_URL}>
                Images
              </NavLink> */}
            </div>
            <div
              className="cursor-pointer text-white text-sm"
              onClick={vendorSignOut}
            >
              Log Out
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
