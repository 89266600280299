import React, { lazy, Suspense, ReactNode } from "react";
import ReactDOM from "react-dom";
import { Router, Location } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { config } from "~utils/utils";
import { ProvideAuth } from "~hooks/useAuth";
import Header from "./Header";
import Loading from "~utils/Loading";

let LoginPage = lazy(() => import("./views/LoginPage"));
let MappingPage = lazy(() => import("./views/MappingPage"));
let UploadPage = lazy(() => import("./views/UploadPage"));
let ErrorsReviewPage = lazy(() => import("./views/ErrorsReviewPage"));
let ResultsPage = lazy(() => import("./views/ResultsPage"));
let ReviewsSearchPage = lazy(() => import("./views/ReviewsSearchPage"));
let ReviewsLatestPage = lazy(() => import("./views/ReviewsLatestPage"));
let ImgApprovalPage = lazy(() => import("./views/ImgApprovalPage"));
// let ImageUploadPage = lazy(() => import("./views/ImageUploadPage"));

const App = () => {
  return (
    <ProvideAuth>
      <Header />
      <Suspense fallback={<Loading />}>
        <FadeTransitionRouter>
          <LoginPage path={config.LOGIN_URL as string} />
          <MappingPage path={config.MAPPING_URL as string} />
          <UploadPage default path={config.UPLOAD_URL as string} />
          <ResultsPage path={config.RESULTS_URL as string} />
          <ImgApprovalPage path={config.IMG_REVIEW_URL as string} />
          <ReviewsSearchPage path={config.REVIEWS_SEARCH_URL as string} />
          <ReviewsLatestPage path={config.REVIEWS_LATEST_URL as string} />
          <ErrorsReviewPage path={config.ERRORS_REVIEW_URL as string} />
          {/* <ImageUploadPage path={config.IMG_UPLOAD_URL} /> */}
        </FadeTransitionRouter>
      </Suspense>
    </ProvideAuth>
  );
};

const FadeTransitionRouter = ({ children }: { children: ReactNode }) => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group flex-1 bg-gray-50">
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router location={location} className="router">
            {children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

ReactDOM.render(<App />, document.getElementById("root"));
