import React, { FunctionComponent } from "react";

const Ptag = ({ title, value }: { title: string; value: undefined | string }) =>
  value ? (
    <p>
      <span className="font-semibold">{title}</span>: {value}
    </p>
  ) : null;
interface IError {
  message?: string;
  error?: string;
  details?: {
    error?: string;
    field?: string;
    field_description?: string;
    required_datatype?: string;
  };
}

const FormatError: FunctionComponent<any> = ({
  error
}: {
  error: undefined | IError;
}) =>
  error ? (
    <div>
      <Ptag title="Message" value={error.message} />
      <Ptag title="Error" value={error.error} />
      {error.details && (
        <>
          <Ptag title="Error" value={error.details.error} />
          <Ptag title="Field" value={error.details.field} />
          <Ptag
            title="Field Description"
            value={error.details.field_description}
          />
          <Ptag
            title="Required Datatype"
            value={error.details.required_datatype}
          />
        </>
      )}
    </div>
  ) : null;

export default FormatError;
