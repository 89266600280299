import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
  useEffect
} from "react";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";

import { useFieldsMappingContext } from "~context/FieldsMappingContext";
import { useFileContext } from "~context/FileContext";
import { useHeadersTypeContext } from "~context/HeadersTypeContext";
import { useFileUploadTypeContext } from "~context/FileUploadTypeContext";

import { useAuth } from "~hooks/useAuth";
import Alert from "~utils/Alert";
import { COL_NAME, COL_NUMBER } from "~utils/utils";

const baseStyle = {
  // backgroundColor: "#fafafa",
  // color: "#bdbdbd",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#be4dff"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

// let holdFn;
let asideElem;

interface ITheirMappedHeaders {
  theirField: string;
  ourField: string;
}

const FormFileUpload: FunctionComponent<{
  theirMappedHeaders: ITheirMappedHeaders[];
  // uploadBtnActive: boolean;
  // setUploadBtnActive: Dispatch<SetStateAction<boolean>>;
}> = ({ theirMappedHeaders /*uploadBtnActive, setUploadBtnActive */ }) => {
  const auth = useAuth();
  const [_their, setTheirFieldMapping] = useFieldsMappingContext();
  const [fileContext, setFile] = useFileContext();
  const [onPageHeadersType, _setonpageheaderstype] = useHeadersTypeContext();
  const [fileUploadType, _setfileuploadtype] = useFileUploadTypeContext();

  const [incorrectFileType, setIncorrectFileType] = useState(false);

  // const [uploadBtnLoading, setUploadBtnLoading] = useState(false);
  // const [showUploadErrorAlert, setShowUploadErrorAlert] = useState();
  // const [
  //   mappedTheirToOurFieldHeaders,
  //   setMappedTheirToOurFieldHeaders
  // ] = useState(theirMappedHeaders);

  const incomingHeaderType =
    auth.user[fileUploadType] && auth.user[fileUploadType].csv_mapping_type;

  // const processMappingToAxiosData = () => {
  //   const columnNames: { [x: string]: string | null } = {};
  //   theirMappedHeaders.length &&
  //     theirMappedHeaders.forEach(
  //       ({ theirField, ourField }: ITheirMappedHeaders) =>
  //         (columnNames[theirField] = ourField || null)
  //     );
  //
  //   return {
  //     csv_mapping_type: newHasHeaders() ? COL_NAME : COL_NUMBER,
  //     csv_mapping_columns: newHasHeaders()
  //       ? columnNames
  //       : theirMappedHeaders.map(({ ourField }) => ourField || null)
  //   };
  // };

  const newHasHeaders = () =>
    // use the header on page selector value
    onPageHeadersType
      ? onPageHeadersType === COL_NAME
      : incomingHeaderType === COL_NAME;

  const onDrop = useCallback(acceptedFiles => {
    setIncorrectFileType(false);
    asideElem = document.querySelector(".aside-filelist");
    asideElem && asideElem.classList.remove("hidden");
    setFile(acceptedFiles[0]);
  }, []);

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: [
      ".csv",
      "text/csv",
      "application/csv",
      "text/plain",
      "text/comma-separated-values",
      "application/vnd.ms-excel"
    ],
    multiple: false,
    onDrop,
    onDropRejected: () => setIncorrectFileType(true)
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject]
  );

  // const onClickUploadFile = () => {
  //   if (!uploadBtnActive) return;

  //   setUploadBtnLoading(true);
  //   setShowUploadErrorAlert(undefined);

  //   const allFilesPromises: Promise<string | undefined>[] = fileContext
  //     ? config.urlData[fileUploadType].map(
  //         (path: string) =>
  //           new Promise((resolve, reject) => {
  //             sendFile(
  //               fileContext,
  //               config.host + path,
  //               auth.token,
  //               processMappingToAxiosData(),
  //               resolve,
  //               reject
  //             );
  //           })
  //       )
  //     : [];

  //   Promise.all(allFilesPromises)
  //     .then(values => {
  //       // navigate to show
  //       setUploadBtnLoading(false);
  //       navigate(config.RESULTS_URL, {
  //         replace: false,
  //         state: { data: values }
  //       });
  //     })
  //     .catch(error => {
  //       setUploadBtnActive(false);
  //       setUploadBtnLoading(false);
  //       setShowUploadErrorAlert(error);
  //     });
  // };

  // useEffect(() => {
  //   setShowUploadErrorAlert(undefined);
  // }, [uploadBtnActive, fileContext, fileUploadType]);

  useEffect(() => {
    if (fileContext) {
      Papa.parse(fileContext, {
        header: newHasHeaders(),
        skipEmptyLines: true,
        complete: function(results: any) {
          console.log("twc results", results);
          setTheirFieldMapping({
            headers: newHasHeaders() ? results.meta.fields : [],
            data: results.data.slice(0, 3)
          });
        }
      });
    } else {
      setTheirFieldMapping({ headers: [], data: null });
    }
  }, [fileContext]);

  return (
    <section className="my-5">
      <>
        {incorrectFileType && (
          <Alert color="red" clsName="error-upload">
            <p className="font-bold">
              Incorrect file type, please use CSV file type
            </p>
          </Alert>
        )}
        <div
          {...getRootProps({
            className: "dropzone",
            style
          })}
        >
          <input {...getInputProps()} />
          {isDragReject ? (
            <p>CSV file types only please</p>
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              Drag 'n' drop your CSV file here, or click to select your file
            </p>
          )}
        </div>
        <aside className="aside-filelist text-center hidden">
          {/* <ul>{files}</ul> */}
          {fileContext && (
            <ul>
              <li key={fileContext.path} className="dropped-filename">
                {fileContext.path} - {fileContext.size} bytes
              </li>
            </ul>
          )}
        </aside>
        {/* <div className="my-4 lg:w-1/2 mx-auto">
          <button
            className={
              "align-items upload-submit-btn " +
              (uploadBtnActive && !uploadBtnLoading
                ? "upload-submit-btn--active focus:ring-purple"
                : "upload-submit-btn--disabled focus:ring-gray")
            }
            onClick={onClickUploadFile}
          >
            <span
              className={
                "inline-flex items-center " +
                (uploadBtnActive && !uploadBtnLoading
                  ? "text-white"
                  : "text-gray-600")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="stroke-current stroke-2 w-8 h-8 mr-2"
              >
                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3" />
              </svg>
              <span>
                {uploadBtnLoading ? "Uploading..." : "Upload CSV file"}
              </span>
            </span>
          </button>
        </div> */}
        {/* {showUploadErrorAlert && (
          <Alert color="red">
            <p className="font-bold">
              We found an error while uploading your CSV file:
            </p>
            <FormatError error={showUploadErrorAlert} />

            {showUploadErrorAlert.details && showUploadErrorAlert.details.item && (
              <dl>
                {Object.entries(showUploadErrorAlert.details.item).map(
                  ([key, value]) => (
                    <div key={key}>
                      <dt>{key}</dt>
                      <dd>{value}</dd>
                    </div>
                  )
                )}
              </dl>
            )}
          </Alert>
        )} */}
      </>
    </section>
  );
};

const PTag = ({ title, value }: { title: string; value: undefined | string }) =>
  value ? (
    <p>
      <span className="font-semibold">{title}</span>: {value}
    </p>
  ) : null;
interface IError {
  message?: string;
  error?: string;
  details?: {
    error?: string;
    field?: string;
    field_description?: string;
    required_datatype?: string;
  };
}

const FormatError = ({ error }: { error: undefined | IError }) =>
  error ? (
    <div>
      <PTag title="Message" value={error.message} />
      <PTag title="Error" value={error.error} />
      {error.details && (
        <>
          <PTag title="Error" value={error.details.error} />
          <PTag title="Field" value={error.details.field} />
          <PTag
            title="Field Description"
            value={error.details.field_description}
          />
          <PTag
            title="Required Datatype"
            value={error.details.required_datatype}
          />
        </>
      )}
    </div>
  ) : null;

export default FormFileUpload;
