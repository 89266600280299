import React, {
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useState
} from "react";

const FileUploadTypeContext = createContext<
  [
    string,
    Dispatch<
      SetStateAction<
        | ""
        | "csv_mapping_inventory"
        | "csv_mapping_stock"
        | "csv_mapping_combined"
      >
    >
  ]
>(["", type => type]);

const useFileUploadTypeContext = () => {
  return useContext(FileUploadTypeContext);
};

const ProvideFileUploadType = ({ children }) => {
  const fileUploadTypeState = useState("");
  return (
    <FileUploadTypeContext.Provider value={fileUploadTypeState}>
      {children}
    </FileUploadTypeContext.Provider>
  );
};

export {
  FileUploadTypeContext,
  useFileUploadTypeContext,
  ProvideFileUploadType
};
