import React, {
  SetStateAction,
  Dispatch,
  FunctionComponent,
  useState,
  useEffect
} from "react";
import { navigate } from "@reach/router";
import { useFileContext } from "~context/FileContext";
import { useHeadersTypeContext } from "~context/HeadersTypeContext";
import { useFileUploadTypeContext } from "~context/FileUploadTypeContext";
import { COL_NAME, COL_NUMBER, config, axiosPostMappings } from "~utils/utils";
import { useAuth } from "~hooks/useAuth";
import useDataApi from "~hooks/useDataApi";
import sendFile from "~hooks/useSendFile";

interface ITheirMappedHeaders {
  theirField: string;
  ourField: string;
}

const UploadFileBtn: FunctionComponent<{
  setUploadBtnActive: Dispatch<SetStateAction<boolean>>;
  uploadBtnActive: boolean;
  theirMappedHeaders: ITheirMappedHeaders[];
  showUploadErrorAlert: any;
  setShowUploadErrorAlert: Dispatch<SetStateAction<any>>;
  reMapBtn: string;
  onClickReMapHeaders: (e: React.MouseEvent) => void;
}> = ({
  theirMappedHeaders,
  uploadBtnActive,
  setUploadBtnActive,
  showUploadErrorAlert,
  setShowUploadErrorAlert,
  reMapBtn,
  onClickReMapHeaders
}) => {
  const auth = useAuth();
  const [fileContext] = useFileContext();
  const [fileUploadType] = useFileUploadTypeContext();
  const [onPageHeadersType] = useHeadersTypeContext();
  const [_mappingAxiosState, setMappingAxiosOptions] = useDataApi(null, null);
  const [uploadBtnLoading, setUploadBtnLoading] = useState(false);

  const incomingHeaderType =
    auth.user[fileUploadType] && auth.user[fileUploadType].csv_mapping_type;

  const newHasHeaders = () =>
    // use the header on page selector value
    onPageHeadersType
      ? onPageHeadersType === COL_NAME
      : incomingHeaderType === COL_NAME;

  const processMappingToAxiosData = () => {
    const columnNames: { [x: string]: string | null } = {};
    theirMappedHeaders.length &&
      theirMappedHeaders.forEach(
        ({ theirField, ourField }: ITheirMappedHeaders) =>
          (columnNames[theirField] = ourField || null)
      );

    return {
      csv_mapping_type: newHasHeaders() ? COL_NAME : COL_NUMBER,
      csv_mapping_columns: newHasHeaders()
        ? columnNames
        : theirMappedHeaders.map(({ ourField }) => ourField || null)
    };
  };

  const sendAxiosMapping = () => {
    // fileUploadType; // inventoryOnly
    setMappingAxiosOptions(
      axiosPostMappings({
        token: auth.token,
        data: { [fileUploadType]: processMappingToAxiosData() }
      })
    );
    // if successfully sent update auth.user data
  };

  async function* asyncGenerator(): any {
    let i = 0;
    try {
      while (i < config.urlData[fileUploadType].length) {
        yield await new Promise((resolve, reject) => {
          sendFile(
            fileContext,
            config.host + config.urlData[fileUploadType][i],
            auth.token,
            [{ field: "mapping", fieldData: processMappingToAxiosData() }],
            resolve,
            reject
          );
        });
        i++;
      }
    } finally {
    }
  }

  const onClickUploadFile = async () => {
    if (!uploadBtnActive) return;

    try {
      setUploadBtnLoading(true);
      setShowUploadErrorAlert(undefined);
      let responseItems;

      for await (const sendFileResponseData of asyncGenerator()) {
        if (fileUploadType === config.userMapping.inventoryAndStock) {
          if (/\/inventory\/update\/csv/.test(sendFileResponseData.path)) {
            responseItems = sendFileResponseData.response;
          }
        } else {
          responseItems = sendFileResponseData.response;
        }
      }
      setUploadBtnLoading(false);
      sendAxiosMapping();
      // remap user field mapping to new mapping
      auth.fns.updateUser(fileUploadType, processMappingToAxiosData());
      navigate(config.RESULTS_URL as string, {
        replace: false,
        state: { data: responseItems, dummy: "SET BY PHIL" }
      });
    } catch (error) {
      setUploadBtnActive(false);
      setUploadBtnLoading(false);
      setShowUploadErrorAlert(error.response);
    }
  };

  const onClickScrollToError = () => {
    const errorElem = document.querySelector(".error-upload");
    errorElem && errorElem.scrollIntoView();
  };

  useEffect(() => {
    setShowUploadErrorAlert(undefined);
  }, [uploadBtnActive, fileContext, fileUploadType]);

  return (
    <div className="flex lg:flex-row flex-col w-3/4 mx-auto items-center justify-center py-2 relative">
      <div className="md:w-1/3 w-11/12">
        <button
          className={
            "align-items upload-submit-btn text-base uppercase bg-twc-btn-login-100 border-2 border-twc-btn-login-100 hover:bg-twc-btn-login-200 hover:border-twc-btn-login-200 hover:border-2 focus:outline-none" +
            (uploadBtnActive && !uploadBtnLoading
              ? " upload-submit-btn--active"
              : " upload-submit-btn--disabled focus:ring-gray")
          }
          onClick={onClickUploadFile}
        >
          <span
            className={
              "inline-flex items-center " +
              (uploadBtnActive && !uploadBtnLoading
                ? "text-white"
                : "text-twc-text-color")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-current stroke-2 w-5 h-5 mr-2"
            >
              <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3" />
            </svg>
            <span>{uploadBtnLoading ? "Uploading..." : "Upload CSV file"}</span>
          </span>
        </button>
      </div>

      {["show"].includes(reMapBtn) && (
        <div
          className="mx-4 lg:absolute static my-4 lg:my-0 cursor-pointer right-0"
          onClick={onClickReMapHeaders}
        >
          <div className="py-2 px-4 items-center bg-gray-600 text-gray-100 leading-none lg:rounded-full flex lg:inline-flex">
            Remap Headers
          </div>
        </div>
      )}

      {showUploadErrorAlert && (
        <div
          className="mx-4 lg:absolute static my-4 lg:my-0 cursor-pointer right-0"
          onClick={onClickScrollToError}
        >
          <div
            className="p-2 bg-red-800 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex"
            role="alert"
          >
            <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
              Error
            </span>
            <span className="font-semibold mr-2 text-left flex-auto">
              View Upload Error
            </span>
            <svg
              className="fill-current opacity-75 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFileBtn;
