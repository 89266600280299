import React, { FunctionComponent } from "react";

const Four0Four: FunctionComponent = ({ children }) => {
  return (
    <div className="page">
      <div
        className="flex justify-center items-center"
        style={{ minHeight: "30vh" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Four0Four;
