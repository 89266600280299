import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
  ReactNode
} from "react";

interface IMappedData {
  headers: string[];
  data: { [x: string]: string }[] | null;
}

const FieldsMappingContext = createContext<
  [IMappedData, Dispatch<SetStateAction<IMappedData>>]
>([{ headers: [], data: null }, field => field]);

const useFieldsMappingContext = () => {
  return useContext(FieldsMappingContext);
};

const ProvideFieldsMapping = ({ children }: { children: ReactNode }) => {
  const fieldsMapping = useState({ headers: [], data: null });
  return (
    <FieldsMappingContext.Provider value={fieldsMapping}>
      {children}
    </FieldsMappingContext.Provider>
  );
};

export { FieldsMappingContext, useFieldsMappingContext, ProvideFieldsMapping };

// https://stackoverflow.com/questions/58337475/can-i-use-the-react-context-api-inside-a-context-api-or-do-i-have-to-merge-them
// useMultipleContexts(){
//   const [contextOne, setContextOne] = useContext(ContextOne);
//   const [contextTwo, setContextTwo] = useContext(ContextTwo);
//
//   /**
//    * Do something with both contexts
//    * in a custom hook that can be used
//    * multiple times with the same state
//    */
// }
