import React, { FunctionComponent, useState, useEffect } from "react";
import Clipboard from "react-clipboard.js";
const copiedToClipbord = "copied-to-cb";
const showHideCls = "show-hide";

const Review = ({ review, fields }: any) => {
  const showClipboard = ["notes"];
  const onCopyClick = (e: React.MouseEvent) => {
    window[copiedToClipbord].classList.add(showHideCls);
    setTimeout(() => {
      window[copiedToClipbord].classList.remove(showHideCls);
    }, 1_800);
  };

  const renderValue = (title: string, val: string) => {
    if (title === "vintage") {
      val = val.toString() === "-1" ? "NV" : val;
    }
    if (title === "reviewer") {
      val = `${review.reviewer}, ${review.site_name}`;
    }
    return val.toString().replace(/\n/g, "<br>");
  };
  return (
    <>
      <dl className="flex flex-wrap relative items-center">
        {Object.entries(review).map(([title, value]: any, idx2: number) =>
          fields[title] && value ? (
            <React.Fragment key={idx2}>
              <dt
                id={review.uid}
                className="lg:w-1/4 md:w-1/3 w-full text-left md:text-right pr-6"
              >
                {fields[title]}
                {showClipboard.includes(title) && (
                  <>
                    <br />
                    <Clipboard
                      data-clipboard-text={value}
                      onClick={onCopyClick}
                      className="bg-gray-200 p-1 text-xs"
                    >
                      Copy to clipboard
                    </Clipboard>
                  </>
                )}
              </dt>
              <dd
                className={`lg:w-3/4 md:w-2/3 w-full ${
                  title === "notes"
                    ? "p-4 border border-gray-200 mx-0 my-2"
                    : "m-0 pb-2 md:px-1 md:pb-0"
                }`}
                dangerouslySetInnerHTML={{
                  __html: renderValue(title, value)
                }}
              />
            </React.Fragment>
          ) : null
        )}
      </dl>
      <hr />
    </>
  );
};

export default Review;
