import React, {
  useState,
  FunctionComponent,
  ChangeEvent,
  useEffect,
  Dispatch,
  SetStateAction
} from "react";
import { RouteComponentProps } from "@reach/router";
import { useHeadersTypeContext } from "~context/HeadersTypeContext";
import { useFileUploadTypeContext } from "~context/FileUploadTypeContext";

import useDataApi from "~hooks/useDataApi";
import {
  config,
  twcFieldsOptions,
  processOptions,
  buildAxios,
  COL_NAME
} from "~utils/utils";
import Alert from "~utils/Alert";
import { useAuth } from "~hooks/useAuth";

interface ITheirMappedHeaders {
  theirField: string;
  ourField: string;
}
interface ITheirMappedDatum {
  [x: string]: string[] | [];
}
interface ILabelValue {
  label: string;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
}
interface ITheir {
  colNum: number;
  theirFieldHeader: string;
  newHasHeaders: () => boolean;
  fieldSelectionOptions: ILabelValue[];
  theirMappedHeadersWithValues: ITheirMappedDatum;
  theirMappedHeaders: ITheirMappedHeaders[];
  setTheirMappedHeaders: Dispatch<SetStateAction<ITheirMappedHeaders[]>>;
}

const TheirFieldHeaders: FunctionComponent<ITheir> = ({
  colNum,
  theirFieldHeader,
  newHasHeaders,
  theirMappedHeadersWithValues, // {apple: Array(3), banana: Array(3), carrot: Array(3)}
  fieldSelectionOptions, // [{label: "Please select a Field", value: ""}]
  theirMappedHeaders, // [{theirField: "apple", ourField: ""}]
  setTheirMappedHeaders
}) => {
  const [currentField, setCurrentField] = useState("");

  const onSelectChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value;
    const dataCopy = JSON.parse(JSON.stringify(theirMappedHeaders));
    const currentSelection = {
      theirField: theirFieldHeader,
      ourField: value
    };

    // csv header not in theirMappedHeaders, then add to dataCopy
    dataCopy.find(
      (obj: { theirField: string }) => obj.theirField === theirFieldHeader
    ) || dataCopy.push(currentSelection);

    dataCopy.forEach((obj: ITheirMappedHeaders, index: number) => {
      if (obj.ourField === value) {
        // reset the previous field using this value
        dataCopy[index] = { theirField: obj.theirField, ourField: "" };
      }
      if (obj.theirField === theirFieldHeader) {
        dataCopy[index] = currentSelection;
      }
    });
    setCurrentField(value);
    setTheirMappedHeaders(dataCopy);
  };

  useEffect(() => {
    const currentFieldData = theirMappedHeaders.filter(
      obj =>
        obj &&
        // this `tr` matches this mappedTheirToOurFieldHeaders
        obj.theirField === theirFieldHeader &&
        // ourField is an option that can be selected
        fieldSelectionOptions.filter(f => f.value === obj.ourField).length
    )[0];

    currentFieldData && setCurrentField(currentFieldData.ourField);
  }, [currentField, theirMappedHeaders, fieldSelectionOptions]);

  return (
    <tr>
      <td
        className={`border px-4 py-2 w-1/2 lg:w-3/5 align-top ${
          currentField ? "bg-white" : "bg-red-100"
        }`}
      >
        <div className="text-gray-900 font-semibold">
          {newHasHeaders() ? theirFieldHeader : `Column ${colNum}`}
        </div>
        {theirMappedHeadersWithValues[theirFieldHeader].map((val, index) => (
          <div key={`${val}-${index}`} className="text-gray-500 ml-2 text-sm">
            {val}
          </div>
        ))}
      </td>
      <td
        className={`border px-4 py-2 w-1/2 lg:w-2/5 align-top ${
          currentField ? "bg-white" : "bg-red-100"
        }`}
      >
        <div className="text-gray-600 text-sm mb-2">Mapped To</div>
        <div className="inline-block relative w-64">
          <select
            value={currentField}
            onChange={onSelectChange}
            className="select-dropdown"
            data-me={currentField}
          >
            {fieldSelectionOptions.map(
              ({ label, value, disabled, hidden }, index) => (
                <option
                  key={value + index}
                  value={value}
                  disabled={disabled}
                  hidden={hidden}
                >
                  {label}
                </option>
              )
            )}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </td>
    </tr>
  );
};

const FieldMappingEditor: FunctionComponent<RouteComponentProps<{
  setUploadBtnActive: Dispatch<SetStateAction<boolean>>;
  setTheirMappedHeaders: Dispatch<SetStateAction<ITheirMappedHeaders[]>>;
  theirMappedHeaders: ITheirMappedHeaders[];
  theirMappedHeadersWithValues: ITheirMappedDatum;
  introHeading?: string;
  showUploadBar: boolean;
  setShowUploadBar: Dispatch<SetStateAction<boolean>>;
}>> = ({
  setUploadBtnActive,
  setTheirMappedHeaders,
  theirMappedHeadersWithValues,
  theirMappedHeaders = [],
  introHeading = "",
  showUploadBar,
  setShowUploadBar
}) => {
  const [onPageHeadersType, _setheaders] = useHeadersTypeContext();
  const [fileUploadType, _setupload] = useFileUploadTypeContext();

  // const [allRequiredFieldsMet, setAllRequiredFieldsMet] = useState(false);
  const [requiredOptionalData, setRequiredOptionalData] = useState({
    required: [],
    requiredOneOf: [],
    optional: []
  });
  // const [mappingAxiosState, setMappingAxiosOptions] = useDataApi(null, null);
  const [fieldSelectionOptions, setFieldSelectionOptions] = useState([]);
  const auth = useAuth();

  const [optionsFetchState, setFetchOptions] = useDataApi(
    buildAxios(auth.token, "GET", "/options"),
    null
  );

  const newHasHeaders = () =>
    // use the header on page selector value
    onPageHeadersType
      ? onPageHeadersType === COL_NAME
      : inComingHeaderType === COL_NAME;

  const inComingHeaderType =
    auth.user[fileUploadType] && auth.user[fileUploadType].csv_mapping_type;

  const requiredFieldsLength = () =>
    requiredOptionalData.required.length +
    (requiredOptionalData.requiredOneOf.length ? 1 : 0);

  useEffect(() => {
    const selectedVals = theirMappedHeaders
      .filter(x => x.ourField)
      .map(x => x.ourField);
    const copiedObj = JSON.parse(JSON.stringify(requiredOptionalData));
    for (const key in copiedObj) {
      if (copiedObj.hasOwnProperty(key)) {
        copiedObj[key].forEach(({ label, value }, idx) => {
          selectedVals.includes(value) &&
            (copiedObj[key][idx].label = "• " + copiedObj[key][idx].label);
        });
      }
    }
    setFieldSelectionOptions(twcFieldsOptions(copiedObj));
  }, [requiredOptionalData, theirMappedHeaders]);

  useEffect(() => {
    if (!optionsFetchState.data) return;
    const apiTypes = config.optionTypes[fileUploadType];
    apiTypes &&
      setRequiredOptionalData(processOptions(apiTypes, optionsFetchState.data));
  }, [fileUploadType, optionsFetchState]);

  // const processMappingToAxiosData = () => {
  //   const columnNames: { [x: string]: string | null } = {};
  //   theirMappedHeaders.length &&
  //     theirMappedHeaders.forEach(
  //       ({ theirField, ourField }: ITheirMappedHeaders) =>
  //         (columnNames[theirField] = ourField || null)
  //     );

  //   return {
  //     csv_mapping_type: newHasHeaders() ? COL_NAME : COL_NUMBER,
  //     csv_mapping_columns: newHasHeaders()
  //       ? columnNames
  //       : theirMappedHeaders.map(({ ourField }) => ourField || null)
  //   };
  // };

  // const sendAxiosMapping = () => {
  //   // fileUploadType; // inventoryOnly
  //   setMappingAxiosOptions(
  //     axiosPostMappings({
  //       token: auth.token,
  //       data: { [fileUploadType]: processMappingToAxiosData() }
  //     })
  //   );
  //   // if successfully sent update auth.user data
  // };

  // if all required fields are set activate mapping btn
  useEffect(() => {
    const requiredFields = requiredOptionalData
      ? requiredOptionalData.required.map(obj => obj.value)
      : [];
    const oneOfFields = requiredOptionalData
      ? requiredOptionalData.requiredOneOf.map(obj => obj.value)
      : [];
    const selectedFields = theirMappedHeaders
      .map(obj => obj.ourField)
      .filter(x => x); // remove empty ""

    const allSelectedFromRequired =
      selectedFields.length &&
      requiredFields.every(x => selectedFields.includes(x));
    const atLeastOneSelectedFromOneOfRequired =
      !oneOfFields.length || oneOfFields.some(r => selectedFields.includes(r));

    // setAllRequiredFieldsMet(
    //   allSelectedFromRequired && atLeastOneSelectedFromOneOfRequired
    // );
    if (allSelectedFromRequired && atLeastOneSelectedFromOneOfRequired) {
      setUploadBtnActive && setUploadBtnActive(true);
      !showUploadBar && setShowUploadBar && setShowUploadBar(true);
    } else {
      setUploadBtnActive && setUploadBtnActive(false);
    }
  }, [theirMappedHeaders, requiredOptionalData]);

  // useEffect(() => {
  //   if (
  //     allRequiredFieldsMet &&
  //     !mappingAxiosState.isLoading &&
  //     !mappingAxiosState.isError
  //   ) {
  //     auth.fns.updateUser(
  //       config.userMapping[fileUploadType], // inventoryOnly: "csv_mapping_inventory",
  //       processMappingToAxiosData()
  //     );
  //     setUploadBtnActive && setUploadBtnActive(true);
  //   } else {
  //     setUploadBtnActive && setUploadBtnActive(false);
  //   }
  // }, [mappingAxiosState]);

  if (theirMappedHeadersWithValues) {
    return (
      <div className="mt-5">
        {introHeading && (
          <h5 className="border-b pb-2 border-gray-400">{introHeading}</h5>
        )}
        {requiredFieldsLength() >
        Object.keys(theirMappedHeadersWithValues).length ? (
          <Alert color="orange">
            <p className="font-bold">Required Fields Mismatch</p>
            <p>
              The number of required fields in the current CSV file (
              <strong>
                {Object.keys(theirMappedHeadersWithValues).length}
              </strong>
              ) is less than the required number of fields (
              <strong>{requiredFieldsLength()}</strong>) for this upload type.
            </p>
          </Alert>
        ) : null}

        {/* {false && (
          <StickyContainer>
            <div className="flex my-4 flex-col lg:flex-row">
              <div className="lg:w-3/4 lg:mr-4">
                {mappingAxiosState.isError && (
                  <Alert color="red">
                    <p className="font-bold">
                      Looks like there was an error setting your fields mapping
                    </p>
                    <p>{mappingAxiosState.data.message}</p>
                  </Alert>
                )}
              </div>

              {allRequiredFieldsMet && !mappingAxiosState.isLoading ? (
                <div className="lg:w-1/4">
                  <button
                    className="mapping-submit-btn mapping-submit-btn--active focus:ring-purple"
                    onClick={sendAxiosMapping}
                  >
                    Submit Mapping
                  </button>
                </div>
              ) : (
                <div className="lg:w-1/4">
                  <button className="mapping-submit-btn mapping-submit-btn--disabled focus:ring-gray">
                    {mappingAxiosState.isLoading
                      ? "loading..."
                      : "Submit Mapping"}
                  </button>
                </div>
              )}
            </div>
          </StickyContainer>
        )} */}

        {Object.keys(theirMappedHeadersWithValues).length ? (
          <div className="py-4">
            <table className="table-auto w-full">
              <tbody>
                {Object.keys(theirMappedHeadersWithValues).map(
                  (theirFieldHeader, index) => (
                    <TheirFieldHeaders
                      key={theirFieldHeader.toLowerCase().replace(/\s+/g, "_")}
                      colNum={index + 1}
                      theirFieldHeader={theirFieldHeader}
                      newHasHeaders={newHasHeaders}
                      theirMappedHeadersWithValues={
                        theirMappedHeadersWithValues
                      } // {apple: Array(3), banana: Array(3), carrot: Array(3)}
                      fieldSelectionOptions={fieldSelectionOptions} // [{label: "Please select a Field", value: ""},]
                      theirMappedHeaders={theirMappedHeaders} // // [{theirField: "apple", ourField: ""}]
                      setTheirMappedHeaders={setTheirMappedHeaders}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <Alert color="red">
        <p className="font-bold">Looks like the CSV file is malformed.</p>
        <p>
          Please ensure the file is correctly formatted and you've selected the
          correct HEADER row option
        </p>
      </Alert>
    );
  }
};

// const StickyContainer: FunctionComponent = ({ children }) => {
//   const stickeyRef = useRef(null);
//   const ffBrowser = /Firefox/i.test(window.navigator.userAgent);
//   const mouseWheelEvt = ffBrowser ? "DOMMouseScroll" : "mousewheel"; // FF doesn't recognize mousewheel as of FF3.
//   let lastKnownScrollPosition = 0;
//   let ticking = false;

//   const containerPos = () => {
//     if (!stickeyRef) return -1;

//     const bodyTop = document.body.getBoundingClientRect().top;
//     const elemTop = stickeyRef.current.getBoundingClientRect().top;
//     return elemTop - bodyTop; // headerElemHeight()
//   };

//   const checkFixedBarOnScroll = (scrollPos: number) => {
//     if (!stickeyRef) return;
//     const pos = containerPos() - 16; // 1rem

//     if (scrollPos > pos) {
//       stickeyRef.current.childNodes[0].classList.add(
//         "container",
//         "mapped-fixed"
//       );
//     } else {
//       stickeyRef.current.childNodes[0].classList.remove(
//         "container",
//         "mapped-fixed"
//       );
//     }
//   };

//   const scrollEvent = () => {
//     lastKnownScrollPosition =
//       window.scrollY ||
//       document.documentElement.scrollTop ||
//       document.body.scrollTop;

//     if (!ticking) {
//       window.requestAnimationFrame(function() {
//         window.matchMedia("(min-width: 767px)").matches &&
//           checkFixedBarOnScroll(lastKnownScrollPosition);
//         ticking = false;
//       });
//       ticking = true;
//     }
//   };

//   useEffect(() => {
//     // console.log("addEventListener...");
//     ffBrowser
//       ? window.addEventListener(mouseWheelEvt, scrollEvent)
//       : window.addEventListener("scroll", scrollEvent);
//     return () => {
//       // console.log("removeEventListener...");
//       ffBrowser
//         ? window.removeEventListener(mouseWheelEvt, scrollEvent)
//         : window.removeEventListener("scroll", scrollEvent);
//     };
//   }, []);

//   return (
//     <div className="my-4" style={{ minHeight: "45px" }} ref={stickeyRef}>
//       <div>{children}</div>
//     </div>
//   );
// };

export default FieldMappingEditor;
