// ARTICLE : https://www.robinwieruch.de/react-hooks-fetch-data
// CODE    : https://github.com/the-road-to-learn-react/use-data-api

import "@babel/polyfill";
import { useState, useEffect, useReducer } from "react";
import axios from "axios";

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload
      };
    case "FETCH_RESET":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: null
      };
    default:
      throw new Error();
  }
};

const useDataApi = (initialFetchOptions, initialData) => {
  // const [url, setUrl] = useState(initialUrl);
  const [fetchOptions, setFetchOptions] = useState(initialFetchOptions);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    successfullySent: function() {
      return !this.isLoading && !this.isError && this.data;
    }
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });

      try {
        let result;
        if (fetchOptions) {
          result = await axios(fetchOptions);
        }

        if (!didCancel) {
          dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        }
      } catch (error) {
        if (!didCancel) {
          const errMsg = error?.response && error.response.data;
          dispatch({ type: "FETCH_FAILURE", payload: errMsg });
        }
      }
    };

    if (fetchOptions === "RESET") {
      dispatch({ type: "FETCH_RESET" });
    } else {
      fetchOptions && fetchData();
    }

    return () => {
      didCancel = true;
    };
    // }, [url]);
  }, [fetchOptions]);

  // return [state, setUrl];
  return [state, setFetchOptions];
};

export default useDataApi;
